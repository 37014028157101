import {
  Config,
  ConfigWithGroup,
  ConfigWithGroupAndLogo,
  ConfigWithLogo,
  ConfigWithGroupAndLogoAndDescription,
} from "~/types/shared";

export const useGetEnabledConfig = <
  G,
  T extends
    | Config<G>
    | ConfigWithLogo<G>
    | ConfigWithGroup<G>
    | ConfigWithGroupAndLogo<G>
    | ConfigWithGroupAndLogoAndDescription<G>,
>(
  config: Array<T>,
) => {
  return config.filter((val) => val.enabled);
};
